<template>
  <div class="base-box">
    <UploadInfoCom
      :options="options"
      @uploadSuccess="uploadSuccessCallBack"
    />
    <SearchInput
      @searchInput="searchInput"
    />
    <SwitchTable
      @changeAsyncType="changeAsyncType"
      :asyncInfo="asyncInfo"
    />
    <TableList
      v-if="showCom"
      :dataList="dataList"
      :total="total"
      :listType="listType"
      :pageNum="pageNum"
      :pageSize="pageSize"
      @operate="operate"
      @currentPageChange="currentPageChange"
      @pageSizeChange="pageSizeChange"
    />
  </div>
</template>

<script>
import dataMoveMixin from '@/api/dataMoveMixin'
import UploadInfoCom from '@/components/UploadInfoCom/Index.vue'
import TableList from '@/components/UploadInfoCom/TableList.vue'
import SearchInput from '@/components/SearchInput/Index.vue'
import SwitchTable from '@/components/UploadInfoCom/SwitchTable.vue'
export default {
  name: "modifyMove",
  mixins: [dataMoveMixin],
  data() {
    return{
      // new-------------------------------------
      asyncInfo: {}, //切换组件数据
      isDisabled: false, // 按钮置灰
      listType: '0',
      showCom: true,
      dataList: [], // 列表数据
      total: 0, // 分页数据
      pageSize: 10, // 页面数据条数
      pageNum: 1, // 页数
      currrentData: {},
      companyName: '', // 搜索内容
      // new-------------------------------------
      options: [
        {
          value: 'COST',
          label: '老成本'
        }
      ]
    }
  },
  components: {
    UploadInfoCom,
    TableList,
    SearchInput,
    SwitchTable
  },
  methods: {
    init() {
      this.pageNum = 1
      this.getModifyInformation()
    },
    // 上传成功回调
    uploadSuccessCallBack() {
      this.init()
    },
    // 切换列表
    changeAsyncType(val) {
      this.showCom = false
      if (val === 'Y') {
        this.listType = '1'
      } else {
        this.listType = '0'
      }
      this.init()
    },
    // 分页-切换页码
    currentPageChange(val) {
      this.pageNum = val
      this.getModifyInformation()
    },
    // 分页-却换pageSize
    pageSizeChange(val) {
      this.pageSize = val
      this.init()
    },
    // 搜索
    searchInput(val) {
      this.companyName = val
      this.init()
    },
    // 事件选择
    operate(type, val) {
      this.currrentData = val
      if (type === 'cancel') {
        this.unSyncModify()
      } else if (type === 'delete') {
        this.deleteDMModify()
      }
    },
    // 删除
    deleteDMModify() {
      this.$request.get(this.URL.deleteDMModify + '?id=' + this.currrentData.id).then(res => {
        if (res.code === '200') {
          this.init()
        }
      })
    },
    // 查询列表数据
    getModifyInformation() {
      // 1-已同步 0-未同步
      var obj = {
        params: {
          name: this.companyName,
          type: this.listType,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
      }
      this.$request.post(this.URL.modifyMoveList, obj).then(res => {
        this.showCom = true
        if (res.code === '200') {
          this.total = Number(res.data.modifyInfo.total)
          var obj = {unsynchronized: '0', synchronization: '0'}
          if (this.listType === '1') {
            // 同步
            obj.synchronization = this.total
            obj.unsynchronized = res.data.count - this.total
          } else {
            // 未同步
            obj.unsynchronized = this.total
            obj.synchronization = res.data.count - this.total
          }
          this.asyncInfo = obj
          this.dataList = res.data.modifyInfo.list
        }
      })
    },
    //取消同步
    unSyncModify(val) {
      this.$request.get(this.URL.unSyncModify + '?id=' + this.currrentData.id).then(res => {
        if (res.code === '200') {
          this.init()
        }
      })
    },
    
    // new-------------------------------    
  },
  mounted() {
    this.init()
  },
  created() {
  }
};
</script>
<style lang="less" scoped>
</style>